import React from 'react'
import pluralize from 'pluralize'
import PropTypes from 'prop-types'
import CardWrapper from '../CardWrapper/dynamic'
import GraphCounting from '../GraphCounting/dynamic'
import SkeletonAnimation from '../SkeletonAnimation/dynamic'
import VisitorCountStyle from './styled'

import { withTranslation } from '../../../i18n'

const COLORS = ['#971214', '#cc7809']

export class VisitorCount extends React.Component {
  renderPieChart() {
    const { data, totalVisitor, loading } = this.props
    return (
      <GraphCounting
        className="visitor-count-piechart"
        data={data}
        countingTotal={totalVisitor}
        countingText={pluralize(this.props.t('total_visitors'), totalVisitor)}
        colors={COLORS}
        loading={loading}
      />
    )
  }

  renderDataTest(visitorType) {
    switch (visitorType) {
      case 'Members':
        return 'lbl-visitor-type-members'
      case 'Non-Members':
        return 'lbl-visitor-type-non-members'
      default:
        return 'lbl-visitor-type-other'
    }
  }

  replaceValue(val) {
    return val?.replace('.0', '')
  }

  renderData() {
    const { data } = this.props
    return (
      <div className="visit-wrapper">
        {data.map((visitorType, index) => {
          let output = (
            <div className="visitor-type-wrapper" key={`${visitorType}${index}`}>
              <div className="visitor-type-detail">
                <div className="visitor-type-color" style={{ backgroundColor: COLORS[index] }} />
                <div className="visitor-type-label" data-test={this.renderDataTest(visitorType.name)}>
                  {this.props.t(visitorType.name.toLowerCase())}
                </div>
              </div>
              <div className="visitor-type-value" data-test={`${this.renderDataTest(visitorType.name)}-value`}>
                <div className="visitor-type-value-percentage">{`${this.replaceValue(visitorType.percentage)}%`}</div>
                <div className="visitor-type-value-count">{`${visitorType.value}`}</div>
              </div>
            </div>
          )
          if (this.props.loading) {
            output = <SkeletonAnimation id="visit-data-skeleton-loading" className="loading" key={`loading-${index}`} />
          }
          return output
        })}
      </div>
    )
  }

  renderVisitContent() {
    return (
      <div className="visit-content-wrapper">
        {this.renderPieChart()}
        {this.renderData()}
      </div>
    )
  }

  checkMessage(message, val) {
    return message === '' ? val : message
  }

  renderContent() {
    return (
      <CardWrapper title={this.props.t('visitor_count')} id={'header-visitor-type'}>
        {this.renderVisitContent()}
      </CardWrapper>
    )
  }

  render() {
    return <VisitorCountStyle className={this.props.className}>{this.renderContent()}</VisitorCountStyle>
  }
}

VisitorCount.defaultProps = {
  loading: false,
}

VisitorCount.propTypes = {
  t: PropTypes.func.isRequired,
  className: PropTypes.string,
  totalVisitor: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
      percentage: PropTypes.string,
    }),
  ).isRequired,
  loading: PropTypes.bool,
}

export default withTranslation()(VisitorCount)
