import styled from 'styled-components'
import device from '../../utils/device-size'

export default styled.div`
  .visit-content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    .visitor-count-piechart {
      margin-top: 14px;
    }
    .visit-wrapper {
      display: grid;
      grid-template-columns: auto auto;
      grid-column-gap: 5px;
      justify-content: center;
      align-items: center;
      padding-bottom: 26px;
      .loading {
        width: 108px;
        height: 30px;
        margin: 10px 10px;
      }
      .visitor-type-wrapper {
        margin-bottom: 12px;
        position: relative;
        overflow: hidden;
        .visitor-type-detail {
          display: flex;
          flex-direction: row;
          margin-bottom: 8px;
          .visitor-type-color {
            width: 16px;
            height: 16px;
            margin-right: 8px;
          }
          .visitor-type-label {
            font-size: 12px;
            color: #fff;
            width: 84px;
          }
        }
        .visitor-type-value {
          display: flex;
          justify-content: center;
          font-size: 24px;
          color: #fff;
          width: 110px;
          height: 54px;
          position: relative;
          &:hover {
            .visitor-type-value-percentage {
              visibility: hidden;
              opacity: 0;
            }
            .visitor-type-value-count {
              text-align: center;
              visibility: visible;
              opacity: 1;
            }
          }
          .visitor-type-value-percentage {
            width: 100%;
            text-align: center;
            transition: 0.3s;
            visibility: visible;
            opacity: 1;
          }
          .visitor-type-value-count {
            position: absolute;
            left: 0;
            top: 0;
            text-align: center;
            visibility: hidden;
            opacity: 0;
            width: 100%;
            transition: 0.3s;
          }
        }
      }
    }
  }

  @media only screen and (min-width: ${device.minSizeL}) and (max-width: ${device.maxSizeL}) {
    .visit-content-wrapper {
      .visit-wrapper {
        grid-column-gap: 2px;
        .loading {
          margin: 10px 8px;
        }
        .visitor-type-wrapper {
          .visitor-type-detail {
            .visitor-type-color {
              width: 16px;
              height: 16px;
              margin-right: 8px;
            }
            .visitor-type-label {
              font-size: 12px;
              width: 84px;
            }
          }
          .visitor-type-value {
            font-size: 21px;
          }
        }
      }
    }
  }
  @media only screen and (min-width: ${device.minSizeM}) and (max-width: ${device.maxSizeM}) {
    .visit-content-wrapper {
      .visit-wrapper {
        grid-column-gap: 0px;
        .visitor-type-wrapper {
          .visitor-type-detail {
            .visitor-type-color {
              width: 16px;
              height: 16px;
              margin-right: 8px;
            }
            .visitor-type-label {
              font-size: 12px;
              width: 87px;
            }
          }
          .visitor-type-value {
            font-size: 24px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: ${device.maxSizeS}) {
    .visit-content-wrapper {
      .visit-wrapper {
        grid-column-gap: 0px;
        .visitor-type-wrapper {
          .visitor-type-detail {
            .visitor-type-color {
              width: 16px;
              height: 16px;
              margin-right: 8px;
            }
            .visitor-type-label {
              font-size: 12px;
              width: 82px;
            }
          }
          .visitor-type-value {
            font-size: 24px;
          }
        }
      }
    }
  }
`
